import React, { Component } from 'react'
import { BrowserRouter as Link } from 'react-router-dom';


export default class Footer extends Component {
    render() {
        return (
            <>
                {/* <footr> */}
                    <div className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 offset-md-3">
                                    <ul className="sociel">
                                        <li> <Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                                        <li> <Link to="/"><i className="fa fa-twitter"></i></Link></li>
                                        <li> <Link to="/"><i className="fa fa-instagram"></i></Link></li>
                                        <li> <Link to="/"><i className="fa fa-linkedin"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                    <div className="contact">
                                        <h3>contact us</h3>
                                        <span>123 Second Street Fifth Avenue,<br /> Manhattan,<br /> New York +987 654 3210</span>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                    <div className="contact">
                                        <h3>ADDITIONAL LINKS</h3>
                                        <ul className="lik">
                                            <li> <Link to="/">About us</Link></li>
                                            <li> <Link to="/">Terms and conditions</Link></li>
                                            <li> <Link to="/">Privacy policy</Link></li>
                                            <li> <Link to="/">News</Link></li>
                                            <li> <Link to="/">Contact us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                    <div className="contact">
                                        <h3>service</h3>
                                        <ul className="lik">
                                            <li> <Link to="/"> Data recovery</Link></li>
                                            <li> <Link to="/">Computer repair</Link></li>
                                            <li> <Link to="/">Mobile service</Link></li>
                                            <li> <Link to="/">Network solutions</Link></li>
                                            <li> <Link to="/">Technical support</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                    <div className="contact">
                                        <h3>About lighten</h3>
                                        <span>Upgrade your workspace with the Lighten, designed to provide optimal lighting for enhanced productivity and comfort </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="copyright">
                            <p>Copyright 2019 All Right Reserved By <Link to="https://html.design/">Free html Templates</Link> Distributed By <Link to="https://themewagon.com">ThemeWagon </Link></p>
                        </div >
                    </div>
                {/* </footr >     */}
            </>
        )
    }
}
