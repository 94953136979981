import React, { Component } from 'react';
import { BrowserRouter as Link } from "react-router-dom";

export default class Home extends Component {
  render() {
    return (
        <>
        <div>
          <section className="slider_section">
            <div
              id="main_slider"
              className="carousel slide banner-main"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="first-slide"
                    src="./frontend/images/banner2.jpg"
                    alt="First slide"
                  />
                  <div className="container">
                    <div className="carousel-caption relative">
                      <h1>
                        Our <br />{" "}
                        <strong className="black_bold">Latest </strong>
                        <br />
                        <strong className="yellow_bold">Product </strong>
                      </h1>
                      <p>
                        It is a long established fact that a r <br />
                        eader will be distracted by the readable content of a
                        page{" "}
                      </p>
                      <Link to="#">see more Products</Link>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    className="second-slide"
                    src="./frontend/images/banner2.jpg"
                    alt="Second slide"
                  />
                  <div className="container">
                    <div className="carousel-caption relative">
                      <h1>
                        Our <br />{" "}
                        <strong className="black_bold">Latest </strong>
                        <br />
                        <strong className="yellow_bold">Product </strong>
                      </h1>
                      <p>
                        It is a long established fact that a r <br />
                        eader will be distracted by the readable content of a
                        page{" "}
                      </p>
                      <Link to="#">see more Products</Link>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    className="third-slide"
                    src="./frontend/images/banner2.jpg"
                    alt="Third slide"
                  />
                  <div className="container">
                    <div className="carousel-caption relative">
                      <h1>
                        Our <br />{" "}
                        <strong className="black_bold">Latest </strong>
                        <br />
                        <strong className="yellow_bold">Product </strong>
                      </h1>
                      <p>
                        It is a long established fact that a r <br />
                        eader will be distracted by the readable content of a
                        page{" "}
                      </p>
                      <Link to="#">see more Products</Link>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                className="carousel-control-prev"
                to="#main_slider"
                role="button"
                data-slide="prev"
              >
                <i className="fa fa-angle-right"></i>
              </Link>
              <Link
                className="carousel-control-next"
                to="#main_slider"
                role="button"
                data-slide="next"
              >
                <i className="fa fa-angle-left"></i>
              </Link>
            </div>
          </section>

          <div className="whyschose">
            <div className="container">
              <div className="row">
                <div className="col-md-7 offset-md-3">
                  <div className="title">
                    <h2>
                      Why <strong className="black">choose us</strong>
                    </h2>
                    <span>Fastest repair service with best price!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="choose_bg">
            <div className="container">
              <div className="white_bg">
                <div className="row">
                  <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="for_box">
                      <i>
                        <img src="./frontend/icon/1.png" />
                      </i>
                      <h3>Data recovery</h3>
                      <p>
                        Perspiciatis eos quos totam cum minima autPerspiciatis
                        eos quos
                      </p>
                    </div>
                  </dir>
                  <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="for_box">
                      <i>
                        <img src="./frontend/icon/2.png" />
                      </i>
                      <h3>Computer repair</h3>
                      <p>
                        Perspiciatis eos quos totam cum minima autPerspiciatis
                        eos quos
                      </p>
                    </div>
                  </dir>
                  <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="for_box">
                      <i>
                        <img src="./frontend/icon/3.png" />
                      </i>
                      <h3>Mobile service</h3>
                      <p>
                        Perspiciatis eos quos totam cum minima autPerspiciatis
                        eos quos
                      </p>
                    </div>
                  </dir>
                  <dir className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="for_box">
                      <i>
                        <img src="./frontend/icon/4.png" />
                      </i>
                      <h3>Network solutions</h3>
                      <p>
                        Perspiciatis eos quos totam cum minima autPerspiciatis
                        eos quos
                      </p>
                    </div>
                  </dir>
                  <div className="col-md-12">
                    <Link className="read-more">Read More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* START SERVICE */}
          <div className="service">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="title">
                    <h2>
                      Service <strong className="black">Process</strong>
                    </h2>
                    <span>
                      Easy and effective way to get your device repair
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="service-box">
                    <i>
                      <img src="./frontend/icon/service1.png" />
                    </i>
                    <h3>Fast service</h3>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="service-box">
                    <i>
                      <img src="./frontend/icon/service2.png" />
                    </i>
                    <h3>Secure payments</h3>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="service-box">
                    <i>
                      <img src="./frontend/icon/service3.png" />
                    </i>
                    <h3>Expert team</h3>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="service-box">
                    <i>
                      <img src="./frontend/icon/service4.png" />
                    </i>
                    <h3>Affordable services</h3>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="service-box">
                    <i>
                      <img src="./frontend/icon/service5.png" />
                    </i>
                    <h3>90 Days warranty</h3>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea{" "}
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className="service-box">
                    <i>
                      <img src="./frontend/icon/service6.png" />
                    </i>
                    <h3>Award winning</h3>
                    <p>
                      Exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END SERVICE */}

          {/* START PRODUCT */}
          <div className="product">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title">
                    <h2>
                      our <strong className="black">products</strong>
                    </h2>
                    <span>
                      We package the products with best services to make you a
                      happy customer.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-bg">
            <div className="product-bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p1.png" />
                      </i>
                      <h3>Norton Internet Security</h3>
                      <span>$25.00</span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p2.png" />
                      </i>
                      <h3>Norton Chip Security</h3>
                      <span>$20.00</span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p3.png" />
                      </i>
                      <h3>Norton Wallet Security</h3>
                      <span>$30.00</span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p4.png" />
                      </i>
                      <h3>Norton Box Security</h3>
                      <span>$30.00</span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p5.png" />
                      </i>
                      <h3>Norton Purse Security</h3>
                      <span>$35.00</span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p2.png" />
                      </i>
                      <h3>Norton Chip Security</h3>
                      <span>$20.00</span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p6.png" />
                      </i>
                      <h3>Norton Machine Security</h3>
                      <span>$50.00</span>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                    <div className="product-box">
                      <i>
                        <img src="./frontend/icon/p7.png" />
                      </i>
                      <h3>Norton Cables Security</h3>
                      <span>$30.00</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Clients_bg_white">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="title">
                      <h3>What Clients Say?</h3>
                    </div>
                  </div>
                </div>
                <div
                  id="client_slider"
                  className="carousel slide banner_Client"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <Link
                      data-target="#client_slider"
                      data-slide-to="0"
                      className="active"
                    ></Link>
                    <Link data-target="#client_slider" data-slide-to="1"></Link>
                    <Link data-target="#client_slider" data-slide-to="2"></Link>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="container">
                        <div className="carousel-caption text-bg">
                          <div className="img_bg">
                            <i>
                              <img src="./frontend/images/lllll.png" />
                              <span>
                                Jone Due
                                <br />
                                <strong className="date">12/02/2019</strong>
                              </span>
                            </i>
                          </div>

                          <p>
                            You guys rock! Thank you for making it painless,
                            pleasant and most of all hassle free! I wish I would
                            have thought of it first. I am really satisfied with
                            my first laptop service.
                            <br />
                            Highly recommend this e-commerce gem for top-notch
                            service and quality products
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="container">
                        <div className="carousel-caption text-bg">
                          <div className="img_bg">
                            <i>
                              <img src="./frontend/images/lllll.png" />
                              <span>
                                Max Planck
                                <br />
                                <strong className="date">31/07/2011</strong>
                              </span>
                            </i>
                          </div>
                          <p>
                            An exceptional e-commerce experience! From seamless
                            navigation to prompt delivery, every aspect exceeded
                            my expectations. The product quality is top-notch,
                            <br />
                            and their customer service is truly commendable. I
                            highly recommend this website to anyone looking for
                            a hassle-free shopping experience!{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="container">
                        <div className="carousel-caption text-bg">
                          <div className="img_bg">
                            <i>
                              <img src="./frontend/images/lllll.png" />
                              <span>
                                Edwin Hubble
                                <br />
                                <strong className="date">1/01/2017</strong>
                              </span>
                            </i>
                          </div>
                          <p>
                            An absolute gem of an e-commerce site! From seamless
                            navigation to an impressive range of products,
                            shopping here is a breeze.
                            <br />
                            The checkout process is quick and hassle-free, and I
                            love the personalized recommendations. Plus, the
                            prompt delivery exceeded my expectations. Highly
                            recommended!"
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="yellow_bg">
                <div className="row">
                  <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                    <div className="yellow-box">
                      <h3>
                        REQUEST A FREE QUOTE
                        <i>
                          <img src="./frontend/icon/calll.png" />
                        </i>
                      </h3>

                      <p>
                        Get answers and advice from people you want it from.
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                    <div className="yellow-box">
                      <Link to="/">Get Quote</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END PRODUCT */}

          {/* START MAP */}
          <div className="container-fluid padi">
            <div className="map">
              <img src="./frontend/images/mapimg.jpg" alt="img" />
            </div>
          </div>
          {/* END MAP */}
        </div>
      </>
    )
  }
}
