import React, { Component } from "react";

export default class Blogs extends Component {
  render() {
    return (
      <>
        <div className="brand_color">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <h2>blog</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* START LATEST NEWS */}

        <div className="Lastestnews blog">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <span>
                    It is a long established fact that a reader will be
                    distracted by the readable <br />
                    content of a page when looking at its layout. The point of
                    using Lorem{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin">
                <div className="news-box">
                  <figure>
                    <img src="./frontend/images/1.jpg" alt="img" />
                  </figure>
                  <h3>Live With Music</h3>
                  <span> March 20</span>
                  <span>Comment</span>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters,{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin">
                <div className="news-box">
                  <figure>
                    <img src="./frontend/images/2.jpg" alt="img" />
                  </figure>
                  <h3>Best Music</h3>
                  <span> March 20</span>
                  <span>Comment</span>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters,{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 margin">
                <div className="news-box">
                  <figure>
                    <img src="./frontend/images/3.jpg" alt="img" />
                  </figure>
                  <h3>Live With Music</h3>
                  <span> March 20</span>
                  <span>Comment</span>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters,{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="news-box">
                  <figure>
                    <img src="./frontend/images/3.jpg" alt="img" />
                  </figure>
                  <h3>Live With Music</h3>
                  <span> March 20</span>
                  <span>Comment</span>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters,{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="news-box">
                  <figure>
                    <img src="./frontend/images/1.jpg" alt="img" />
                  </figure>
                  <h3>Live With Music</h3>
                  <span> March 20</span>
                  <span>Comment</span>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters,{" "}
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="news-box">
                  <figure>
                    <img src="./frontend/images/2.jpg" alt="img" />
                  </figure>
                  <h3>Best Music</h3>
                  <span> March 20</span>
                  <span>Comment</span>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters,{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END LATEST NEWS */}
      </>
    );
  }
}
