import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

export default class Header extends Component {
  render() {
    return (
      <>
      <header>
         <div className="header">
            <div className="head_top">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="top-box">
                          <ul className="sociel_link">
                              <li> <Link to="/"><i className="fa fa-facebook-f"></i></Link></li>
                              <li> <Link to="/"><i className="fa fa-twitter"></i></Link></li>
                              <li> <Link to="/"><i className="fa fa-instagram"></i></Link></li>
                              <li> <Link to="/"><i className="fa fa-linkedin"></i></Link></li>
                          </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="top-box">
                          <p>long established fact that a reader will be </p>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section">
                      <div className="full">
                        <div className="center-desk">
                            <div className="logo"> <Link to="/"><img src="./frontend/images/logo.jpg" alt="logo"/></Link> </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-9 col-sm-9">
                      <div className="menu-area">
                        <div className="limit-box">
                            <nav className="main-menu">
                              <ul className="menu-area-main">
                                  <li className=""> <Link to="/">Home</Link> </li>
                                  <li> <Link to="/about">About</Link> </li>
                                  <li> <Link to="/product">product</Link> </li>
                                  <li> <Link to="/blog"> Blog</Link> </li>
                                  <li> <Link to="/contact">Contact</Link> </li>
                                  {/* <li className="mean-last"> <Link to="/">signup</Link> </li> */}
                              </ul>
                            </nav>
                        </div>
                      </div>
                  </div>
                  {/* <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                      <li><Link className="buy" to="/">Login</Link></li>
                  </div> */}
                </div>
            </div>
          </div>
      </header>
      </>
    )
  }
}

