import React, { Component } from "react";

export default class Product extends Component {
  render() {
    return (
      <>
        <div className="brand_color">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <h2>our product</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* START OUR PRODUCT */}
        <div className="product">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title">
                  <span>
                    We package the products with best services to make you a
                    happy customer.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-bg">
          <div className="product-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p1.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p2.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p3.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p4.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p5.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p2.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p6.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p7.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p6.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p1.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p2.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                  <div className="product-box">
                    <i>
                      <img src="./frontend/icon/p4.png" />
                    </i>
                    <h3>Norton Internet Security</h3>
                    <span>$25.00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END OUR PRODUCT */}
      </>
    );
  }
}
