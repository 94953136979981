import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './frontend/components/layouts/Header';
import Footer from './frontend/components/layouts/Footer';
import Main from './frontend/components/layouts/Main';
import Home from './frontend/components/pages/Home';
import About from './frontend/components/pages/About';
import Product from './frontend/components/pages/Product';
import Blog from './frontend/components/pages/Blog'
import Contact from './frontend/components/pages/Contact'



export default class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Header />
          <Main>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/Product" component={Product} />
              <Route exact path="/Blog" component={Blog} />
              <Route exact path="/Contact" component={Contact} />


            </Switch>
          </Main>
          <Footer />
        </Router>        
      </>
    )
  }
}
